





















import Vue from 'vue'
import Component from 'vue-class-component'
import pdf from 'pdfvuer'
import { Prop } from 'vue-property-decorator'

@Component({
  components: { Pdf: pdf },
})
export default class PdfViewer extends Vue {
  // =======================================================================================
  // Props
  // =======================================================================================

  @Prop({ required: true })
  private src: any

  @Prop({ type: String, required: false })
  private openLink: string

  @Prop({ type: String, required: false })
  private downloadLink: string

  private page = 1
  private numPages = 0
  private scale: any = 'page-width'
  private minScale = 0.2
  private maxScale = 2

  private setNumPages(p) {
    this.numPages = p
  }

  private prevPage() {
    this.page = this.page > 1 ? this.page - 1 : 1
  }

  private nextPage() {
    this.page = this.page < this.numPages ? this.page + 1 : 1
  }

  private zoomOut() {
    this.scale -= this.scale > this.minScale ? 0.1 : 0
  }

  private zoomIn() {
    this.scale += this.scale < this.maxScale ? 0.1 : 0
  }
}
